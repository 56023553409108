//** Smooth Scroll  **/
window.addEventListener('load', function() {
	setTimeout(function() {
		if ($(window).width() > 1024) {
			var body = document.body
			var scrollWrap = document.getElementsByClassName("smooth-scroll-wrapper")[0]
			var height = scrollWrap.getBoundingClientRect().height - 1
			var speed = 0.095;
			var offset = 0;
			var scheight = Math.floor(height) - 19;
			body.style.height = Math.floor(scheight) + "px";
			function smoothScroll() {
				offset += (window.pageYOffset - offset) * speed;
				var scroll = "translateY(-" + offset + "px) translateZ(0)";
				scrollWrap.style.transform = scroll;
				callScroll = requestAnimationFrame(smoothScroll);
			}
			window.addEventListener("resize", function(){
				smoothScroll();
			});
			smoothScroll();
		} else {
			console.log("")
		}
	}, 1);

});
//****** Back top top  ******/
window.addEventListener("scroll", function() {
	const progressCircle = document.getElementsByClassName("progress-circle");
	const documentHeight = Math.max(
		document.body.scrollHeight,
		document.body.offsetHeight,
		document.documentElement.clientHeight,
		document.documentElement.scrollHeight,
		document.documentElement.offsetHeight
	);
	const windowHeight =
		  window.innerHeight ||
		  document.documentElement.clientHeight ||
		  document.getElementsByTagName("body")[0].clientHeight;
	const scrollPosition =
		  window.pageYOffset || document.documentElement.scrollTop;
	const percentage = scrollPosition / (documentHeight - windowHeight);
	let circleOffset = 126 * percentage;
	progressCircle[0].style.strokeDashoffset = 126 - circleOffset;
});

/**** Back to top script on click ****/
window.addEventListener("scroll", function() {
	const progressCircle = document.getElementsByClassName("progress-circle");
	const documentHeight = Math.max(
		document.body.scrollHeight,
		document.body.offsetHeight,
		document.documentElement.clientHeight,
		document.documentElement.scrollHeight,
		document.documentElement.offsetHeight
	);
	const windowHeight =
		  window.innerHeight ||
		  document.documentElement.clientHeight ||
		  document.getElementsByTagName("body")[0].clientHeight;
	const scrollPosition =
		  window.pageYOffset || document.documentElement.scrollTop;
	const percentage = scrollPosition / (documentHeight - windowHeight);
	let circleOffset = 126 * percentage;
	progressCircle[0].style.strokeDashoffset = 126 - circleOffset;
});

/*Back to top script on click
		------------------------------------*/
document.getElementById('back-to-top').addEventListener('click', function() {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth'
	});
});
window.addEventListener('scroll', function() {
	const backToTopBtn = document.getElementById('back-to-top');
	if (window.scrollY > 100) {
		backToTopBtn.classList.add('show');
	} else {
		backToTopBtn.classList.remove('show');
	}
});



$(document).ready(function() {
	$('.header-right .social-links').clone().addClass('mob-social').insertAfter('.header-warp nav ul');
	$('#nav-icon1').click(function() {
		$(this).toggleClass('open');
		$('.header-warp nav').slideToggle();
	});	
});